<template>
  <div>
    <div class="card-body pt-0 pb-3 pt-7 card card-custom card-stretch gutter-b">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
          <div class="table-responsive">
            <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
              <thead>
                <tr>
                  <th class="table-mw-150 text-center">Page Name</th>

                  <th class="table-mw-150"></th>
                </tr>
              </thead>
              <tbody v-for="(item, i) in list" v-bind:key="i">
                <tr class="text-center">
                  <td class="table-mw-100">
                    <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ item.value }}</span>
                  </td>

                  <td class="pr-0 text-right table-mw-100">
                    <router-link
                      class="btn btn-light-success font-weight-bolder font-size-sm mt-4"
                      :to="`edit/${item._id}`"
                    >
                      <i class="fas fa-pencil-alt fa-1x mr-1"></i>Edit</router-link
                    >

                    <b-button
                      v-b-modal="'confirm_delete'"
                      v-on:click="detail(item)"
                      class="btn btn-light-danger font-weight-bolder font-size-sm ml-3 mt-4"
                    >
                      <i class="fas fa-trash fa-1x mr-1"></i>Delete</b-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <b-pagination
              v-model="currentPage"
              :list="list"
              :per-page="perPage"
              aria-controls="my-table"
              align="center"
              class="mt-10"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="confirm_delete"
      ok-title="Delete"
      ok-variant="danger"
      cancel-title="Cancel"
      title="Confirm Delete"
      @ok="deletePage"
    >
      <p class="my-4">Are you sure want to delete this page name?</p>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Pages' }])
    this.getPage()
  },
  data() {
    return {
      currentPage: 1,
      perPage: 8,
      list: [],
      selectedId: '',
    }
  },
  methods: {
    getPage() {
      ApiService.setHeader()
      ApiService.get('page').then(({ data }) => {
        this.list = data
      })
    },
    deletePage() {
      ApiService.setHeader()
      ApiService.delete('page/' + this.selectedId).then(() => {
        this.getPage()
      })
    },

    detail(item) {
      this.selectedId = item._id
    },
  },
}
</script>

<style>
.table-mw-150 {
  min-width: 150px;
}
.table-mw-100 {
  min-width: 100px;
}
.mb-25 {
  margin-bottom: 25px !important;
}
</style>
